import { nanoid } from 'nanoid';
import mindBw from '../images/mindswarms-bw-min.jpg';
import mindColor from '../images/mindswarms-color.webp';
import fabricBw from '../images/fabric-bw-min.jpg';
import fabricColor from '../images/fabric-color-min.webp';
import pranaaBw from '../images/pranaa-bw-min.jpg';
import pranaaColor from '../images/pranaa-color-min.jpeg';
import pkColor from '../images/pocketbits-color.webp'
import pkBw from '../images/pocketbits-bw.jpg'

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

export const portBlogsData = [{
  id: nanoid(),
  img: 'project.jpg',
  title: 'ES2021: New Features',
  info: '',
  info2: '',
  url: '',
  repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
  to: 'es-2021-features',
  subtitle: 'Getting on with the new JS jargon',
  backgroundImage: null,
  readTime: '9'
},
{
  id: nanoid(),
  img: 'project.jpg',
  title: 'Unit Testing: Nutshell',
  info: '',
  info2: '',
  url: '',
  repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
  to: 'unit-testing-nutshell',
  subtitle: 'Improve code maintainablity & composability',
  backgroundImage: null,
  readTime: '7'
},]


export const blogsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'ES2021: New Features',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'es-2021-features',
    subtitle: 'Getting on with the new JS jargon',
    statusNew: true,
    blogDate: '12 July 2021',
    readTime: '9'
    
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Unit Testing: Nutshell',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'unit-testing-nutshell',
    subtitle: 'Improve code maintainablity & composability',
    statusNew: false,
    blogDate: '4 Nov 2021',
    readTime: '7'
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Dockerizing React',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'docker-react',
    subtitle: 'How can React devs benefit?',
    statusNew: false,
    blogDate: 'Coming soon',
    readTime: false
  }
];

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'mindswarms',
    tagOne: 'React Native',
    tagTwo: 'Mobile App Development',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'mindswarms',
    imgOne: mindBw,
    imgTwo: mindColor,
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Fabric',
    tagThree: 'React Native',
    tagOne: 'ReactJS',
    tagTwo: 'Mobile & Web App',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'fabric',
    imgOne: fabricBw,
    imgTwo: fabricColor,
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'PranaaFoods',
    tagOne: 'NextJS',
    tagTwo: 'Food Delivery App',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'pranaa',
    imgTwo: pranaaColor,
    imgOne: pranaaBw,
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'PocketBits',
    tagOne: 'ReactJS',
    tagTwo: 'Cryptocurrency',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'pocketbits',
    imgOne:
      pkBw,
    imgTwo:pkColor,
  },
];

export const allWorks = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'mindswarms',
    tagOne: 'React Native',
    tagTwo: 'Mobile App Development',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'mindswarms',
    gradient: 'linear-gradient(to top, #00c6fb 0%, #005bea 100%)',
    subtitle: 'A mobile video survey app',
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Fabric',
    tagOne: 'ReactJS',
    tagTwo: 'Progressive Web App',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'fabric',
    subtitle: 'Platform for market development',
    isDesign: false,
    gradient:
      'radial-gradient(73% 147%, #EADFDF 59%, #ECE2DF 100%), radial-gradient(91% 146%, rgba(255,255,255,0.50) 47%, rgba(0,0,0,0.50) 100%)',
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'PranaaFoods',
    tagOne: 'NextJS',
    tagTwo: 'Food Delivery App',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'pranaa',
    gradient: 'linear-gradient(-225deg, #20E2D7 0%, #F9FEA5 100%)',
    isDesign: false,
    subtitle: 'Online vegan food made easier',
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'PocketBits',
    tagOne: 'ReactJS',
    tagTwo: 'Cryptocurrency',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'pocketbits',
    gradient: 'linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%)',
    isDesign: false,
    subtitle: 'A change for the future',
  },{
    id: nanoid(),
    img: 'project.jpg',
    title: 'Saplings',
    tagOne: 'ReactJS',
    tagTwo: 'Cryptocurrency',
    url: '',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'saplings',
    gradient: 'linear-gradient(to top, #349761 0%,  #42CB80 100%)',
    isDesign: false,
    subtitle: 'Adding greenery to Bengaluru since 2002',
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'HCC Redesign',
    tagOne: 'ReactJS',
    tagTwo: 'Cryptocurrency',
    url: 'https://dribbble.com/shots/16858525-HCC-Redesign-Web',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'fabric',
    gradient: 'linear-gradient(to top, #FFB4B4 0%, #FFB4B4 1%, #FD6062 100%)',
    isDesign: true,
    subtitle: 'A fresh new touch to modern healthcare',
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Khronos',
    tagOne: 'Figma',
    tagTwo: 'Prototyping',
    url: 'https://dribbble.com/shots/16874585-Khronos-Dark-Theme',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'fabric',
    gradient: 'linear-gradient(to top, #49535C 0%, #49535A 1%, #495351 100%)',
    isDesign: true,
    subtitle: 'PC peripherals now within your reach',
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Neu-Weather App',
    tagOne: 'XD',
    tagTwo: 'Modern',
    url: 'https://dribbble.com/shots/16889954-Neu-Weather-Concept-Neumorphic-Design',
    repo: 'https://github.com/M-Zabi', // if no repo, the button will not show up
    to: 'fabric',
    gradient: 'linear-gradient(to top, #f0f0f0 0%, #f1f1f1 1%, #f0f0f3 100%)',
    isDesign: true,
    subtitle: 'A simpler, softer weather info app',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
