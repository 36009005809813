import React, { useState, useEffect } from 'react';
import FullMenu from '../FullMenu/FullMenu';
import { Helmet } from 'react-helmet';
import { Navbar, Container, Button } from 'react-bootstrap';
import { animated, useSpring } from 'react-spring';
import gsap from 'gsap';

const HeroLayout = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuAnimation = useSpring({
    transform: menuOpen ? `translateY(0)` : `translateY(-100%)`,
    opacity: menuOpen ? 1 : 0,
  });

  const logoEnvStyles = useSpring({
    loop: false,
    delay: 300,
    to: [{ opacity: 1, transform: 'translateY(0px)' }],
    from: { opacity: 0, transform: 'translateY(-60px)' },
  });

  gsap.config({ nullTargetWarn: false });

  const handleMenuOnClose = () => {
    setMenuOpen(false);
  };


  return (
    <div style={{ overflow: 'hidden', position: 'relative' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Zabi | ${props.metaTitle}`}</title>
        <html lang={'en'} />
        <meta name="description" content={props.metaDescription} />
      </Helmet>

      <Navbar className="position-absolute static-nav">
        <Container className="justify-content-between" style={{ paddingLeft: '4px' }}>
          <animated.div style={logoEnvStyles}>
            <Navbar.Brand href="/" className="pb-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 0 174.22 89.24"
                style={{ marginLeft: '-4px' }}
              >
                <g data-name="Layer 2">
                  <path
                    fill="#fefefe"
                    d="M95.17 89.24H71.23l1.9-1.33v-67.1L80.18 0h14.87l-1.8 1.24v86.67zM10.73 66.55S10.4 83.88 0 89.24h20.56s-8.83-6.81-9.83-22.69zM30.42 0H6.6l2.46 1.57 29.18 87.67h11.29l11.64-32.76-8.27 11.41zm121.21 0l-45.29 89.13 22.48.11L174.22 0zm-14.1 0c-13.07 1.92-24.82 13-28.94 29V0zm35.57 58.16v31.08h-28.62c19.91-2.46 27.73-25.38 28.62-31.08z"
                    data-name="Layer 1"
                  ></path>
                </g>
              </svg>
            </Navbar.Brand>
          </animated.div>
          <animated.div style={logoEnvStyles}>
            <Button id="sap-link" className="ghost-btn px-0" onClick={() => setMenuOpen(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.211"
                height="18.99"
                viewBox="0 0 30.211 21.99"
              >
                <g id="Group_68" data-name="Group 68" transform="translate(-1637.789 -62.757)">
                  <path
                    id="Path_849"
                    data-name="Path 849"
                    d="M21,6.99A1,1,0,1,0,21,5H9A1,1,0,0,0,9,6.99Z"
                    transform="translate(1646 57.757)"
                    fill="#fff"
                  />
                  <path
                    id="Path_1146"
                    data-name="Path 1146"
                    d="M28.417,6.99c.863,0,1.562-.446,1.562-1s-.7-1-1.562-1H9.562C8.7,5,8,5.446,8,6s.7,1,1.562,1Z"
                    transform="translate(1638.021 67.757)"
                    fill="#fff"
                  />
                  <path
                    id="Path_1147"
                    data-name="Path 1147"
                    d="M36.063,6.99c1.186,0,2.148-.446,2.148-1s-.962-1-2.148-1H10.148C8.961,5,8,5.446,8,6s.961,1,2.148,1Z"
                    transform="translate(1629.789 77.757)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </Button>
          </animated.div>
        </Container>
      </Navbar>
      {menuOpen ? (
        <FullMenu style={menuAnimation} active={menuOpen} onClose={handleMenuOnClose} />
      ) : null}
      {props.children}
    </div>
  );
};

export default HeroLayout;
